import React from "react";
import { Nav } from "react-bootstrap";
import { ReactComponent as LinkedInLogo } from "../Assets/linkedin-logo.svg";
import { ReactComponent as GithubLogo } from "../Assets/github-logo.svg";
import DownloadCV from "../Components/DownloadCV";

export default function ContactSection(props) {
  const email_txt = ["Ali_Najafzadeh", "outlook"];
  return (
    <div className="contact-section">
      <div className="contact-content">
        {/* <div className="title">
          <h1 className="flashy-title">
            <span>{"< "}</span>ALI Najafzadeh<span>{" />"}</span>
          </h1>
        </div> */}
        <div className="email">
          <h2>
            <span>{"Email: "}</span>
            {email_txt[0]}
            <strong>{` [at] `}</strong>
            {email_txt[1]}
            .com
          </h2>
        </div>
        <div className="social">
          {/* linkedin */}
          <Nav.Link
            className="social-logo"
            href="https://www.linkedin.com/in/ali-najafzadeh/"
          >
            <LinkedInLogo />
          </Nav.Link>
          <Nav.Link className="social-logo" href="https://github.com/alinjfz/">
            <GithubLogo />
          </Nav.Link>
        </div>
        <div className="cv">
          <DownloadCV />
        </div>
      </div>
    </div>
  );
}
