import React from "react";
import MainHeader from "../Components/MainHeader";
import Footer from "../Components/Footer";
import AboutSection from "../Components/AboutSection";
import ContactSection from "../Components/ContactSection";
import { Container } from "react-bootstrap";
export default function HomePage(props) {
  return (
    <Container fluid className="full-container header-background">
      {/* <div className="header-background"></div> */}
      <MainHeader />
      <AboutSection />
      <ContactSection />
      <Footer />
    </Container>
  );
}
