import React, { useEffect, useState } from "react";

export default function MainHeader() {
  const [counter, setCounter] = useState(0);
  const animate_text = [
    "Hi! I am Ali Najafzadeh",
    "A Full-stack Developer pursuing his master's in Ai",
    "Python & Javasrcipt are my favorite programming languages",
    "Please checkout my CV or my Linkedin page",
  ];
  useEffect(() => {
    const timer = setInterval(() => {
      let t = 0;
      if (counter < 3) {
        t = counter + 1;
      }
      setCounter(t);
    }, 5000);
    return () => clearInterval(timer);
  }, [counter]);

  const animateClass = (i) => {
    if (i === counter) return "mha-animate glow";
    // if (i === counter - 1 || counter === 4) return "mha-animate fade";
    return "mha-animate disabled";
  };
  return (
    <div className="header">
      <div className="inner-header flex main-header-animation">
        <h1 className={animateClass(0)}>{animate_text[0]}</h1>
        <h1 className={animateClass(1)}>{animate_text[1]}</h1>
        <h1 className={animateClass(2)}>{animate_text[2]}</h1>
        <h1 className={animateClass(3)}>{animate_text[3]}</h1>
      </div>
    </div>
  );
}
