import React from "react";
export default function AboutSection(props) {
  const about_txt = (
    <>
      <h1 className="black-text">An introduction about Ali Najafzadeh</h1>
      <p className="black-text">
        Hello! I'm Ali Najafzadeh, currently pursuing a Master's degree in
        Artificial Intelligence and Adaptive Systems at the University of
        Sussex. My expertise lies in full-stack development, with a focus on
        front-end technologies like React.js and back-end frameworks like Flask
        and Django. I have a strong foundation in AI and machine learning, with
        experience in supervised and unsupervised learning, deep learning, and
        reinforcement learning. My passion for AI and web development drives me
        to continuously improve, and I thrive on developing scalable web
        applications and sophisticated machine learning models.
      </p>
    </>
  );
  return (
    <div className="about-section">
      <div className="about-content">
        <div className="about-text">{about_txt}</div>
      </div>
    </div>
  );
}
