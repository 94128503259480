import React from "react";
import { Col, Row } from "react-bootstrap";
import { ReactComponent as WavesImg } from "../Assets/waves.svg";

export default function Footer(props) {
  return (
    <Row className="copyright">
      <Col>
        <WavesImg />
        <p>
          Copyright &copy; 2024{" "}
          <a href="https://AliNajafzadeh.com/">Ali Najafzadeh</a>, All rights
          reserved. {/* <br /> */}
          Photo by{" "}
          <a
            rel="nofollow"
            href="https://unsplash.com/@frantic?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash"
          >
            Alex Kotliarskyi
          </a>{" "}
          on{" "}
          <a
            rel="nofollow"
            href="https://unsplash.com/photos/QBpZGqEMsKg?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash"
          >
            Unsplash.
          </a>
        </p>
      </Col>
    </Row>
  );
}
