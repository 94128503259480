import React from "react";
import { Container, Row, Card, Button, Col } from "react-bootstrap";

const DownloadCV = (props) => {
  const content = {
    titles: ["Graphic", "Classic", "My CV"],
    text: [
      "This is a creative resume created by me",
      "This is a standard resume of me",
      "Explore a detailed overview of my skills and experiences.",
    ],
    dlbtn: "Download CV",
  };
  const cv1 = (
    <Card bg="dark" text="white">
      <Card.Img bg="secondary" variant="top" src={"../Assets/cv/cv-1.png"} />
      <Card.Body>
        <Card.Title>{content.titles[0]}</Card.Title>
        <Card.Text>{content.text[0]}</Card.Text>
        <a
          alt="cv"
          href="../Assets/cv/Front-end_Reactjs_Ali Najafzadeh_tg.pdf"
          download="Front-end_Reactjs_Ali Najafzadeh_tg.pdf"
        >
          <Button className="dark-dl-btn">{content.dlbtn}</Button>
        </a>
      </Card.Body>
    </Card>
  );
  const cv2 = (
    <Card bg="dark" text="white">
      <Card.Img variant="top" src={"../Assets/cv/cv-2.png"} />
      <Card.Body>
        <Card.Title>{content.titles[1]}</Card.Title>
        <Card.Text>{content.text[1]}</Card.Text>
        <a
          alt="cv"
          href="../Assets/cv/Front-end_Reactjs_Ali Najafzadeh_tc.pdf"
          download="Front-end_Reactjs_Ali Najafzadeh_tc.pdf"
        >
          <Button className="dark-dl-btn">{content.dlbtn}</Button>
        </a>
      </Card.Body>
    </Card>
  );
  const cv3 = (
    <Card bg="dark" text="white">
      <Card.Img variant="top" src={"../Assets/cv/cv-3.png"} />
      <Card.Body>
        <Card.Title>{content.titles[2]}</Card.Title>
        <Card.Text>{content.text[2]}</Card.Text>
        <a
          alt="cv"
          href="../Assets/cv/Ali Najafzadeh-CV.pdf"
          download="Ali_Najafzadeh_CV.pdf"
        >
          <Button className="dark-dl-btn">{content.dlbtn}</Button>
        </a>
      </Card.Body>
    </Card>
  );
  return (
    <Container className="download-cv">
      <Row>
        <Col lg={3}></Col>
        <Col lg={6}>{cv3}</Col>
        <Col lg={3}></Col>
      </Row>
    </Container>
  );
};

export default DownloadCV;
